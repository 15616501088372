import APP_CONFIG from "../app.config";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Vehicle } from "../graphql/generated/graphql";
import Image from "../node_modules/next/image";
import { canBeFinancedByCrediQ } from "../utils/financing";
import { TIER_KEYS } from "../utils/constants";
import { classNames, formatPrice } from "../utils/formatters";
import DeleteVehicleButton from "./DeleteVehicleButton";
import DeleteVehicleModal from "./DeleteVehicleModal";
import EditVehicleButton from "./EditVehicleButton";
import GridItemWatchlistButton from "./GridItemWatchlistButton";
import TierBadge from "./TierBadge";
import VerifiedUserIcon from "./VerifiedUserIcon";
import { EyeSlashIcon } from "@heroicons/react/20/solid";
import { BsYoutube } from "react-icons/bs";
import useWindowDimensions from "../hooks/useViewportWidth";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import ResponsiveBanner from "./ResponsiveBanner";

const TIER_COLORS = {
  // [TIER_KEYS.PLUS]: "bg-emerald-50",
  [TIER_KEYS.MAX]: "bg-blue-50",
  // [TIER_KEYS.PREMIUM]: "bg-emerald-50",
};

type TProps = {
  items: Vehicle[];
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  currentVehicleSeller?: string;
  loading?: boolean;
  noPadding?: boolean;
  limit?: number;
  isCurrentUser?: boolean;
  colsClassNames?: string;
  transformation?: string;
  EmptyStateComponent?: (props: unknown) => React.ReactElement;
  LastElement?: () => React.ReactElement;
};

const ItemComponent = ({
  index,
  item,
  isNewListing,
  isFromSameSeller,
  isCurrentUser,
  toggleDeleteModal,
  loading,
  transformation,
  colsClassNames,
  limit,
}: {
  index: number;
  limit: number;
  item: Vehicle;
  colsClassNames: string;
  isNewListing: boolean;
  isFromSameSeller: boolean;
  isCurrentUser: boolean;
  loading: boolean;
  transformation?: string;
  toggleDeleteModal: (vehicle?: Vehicle) => void;
}) => {
  const router = useRouter();

  const showCrediQLogo = canBeFinancedByCrediQ(item);
  if (loading)
    return (
      <GridLoadingSkeleton limit={limit} gridColsClassNames={colsClassNames} />
    );
  return (
    <Link key={item.slug} href={`/autosusados/${item.slug}`}>
      <div
        className={classNames(
          "group relative cursor-pointer pb-2"
          // item.listingTier && item.listingTier !== TIER_KEYS.FREE
          //   ? TIER_COLORS[item.listingTier]
          //   : ""
        )}
      >
        <div className="relative w-full min-h-60 bg-gray-200 aspect-w-4 aspect-h-3  overflow-hidden group-hover:opacity-75 rounded-md shadow-md">
          <div className="h-full absolute">
            <div className="absolute right-1 top-1 z-10">
              <TierBadge tier={item.listingTier} />
            </div>
            <Image
              style={{
                objectFit: "cover",
              }}
              fill
              sizes={
                transformation === "t_medium"
                  ? "(max-width: 768px) 100vw, (max-width: 1200px) 25vw"
                  : "(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
              }
              src={item.thumbs?.length ? item.thumbs[0] : item.pictures[0]}
              alt={`Imagen de ${item.brand} ${item.model} ${item.year}`}
              className={"w-full h-full object-center object-cover"}
            />
            {isNewListing && !isFromSameSeller ? (
              <div
                className={classNames(
                  "absolute bottom-0 w-full h-5 flex items-center justify-center bg-movi-yellow text-white font-medium text-sm"
                )}
              >
                Nueva publicación
              </div>
            ) : null}
            {isFromSameSeller ? (
              <div
                className={classNames(
                  "absolute bottom-0 w-full h-5 flex items-center justify-center bg-orange-500 text-white font-medium text-sm"
                )}
              >
                Mismo vendedor
              </div>
            ) : null}
          </div>
        </div>
        <div className="relative mt-2 flex justify-between flex-col px-1">
          <div className="flex justify-between">
            {item.hidePrice ? (
              <p className="flex gap-x-1 items-center font-medium text-gray-500 text-sm sm:text-base">
                <EyeSlashIcon className="h-4 w-4" />
                Precio oculto
              </p>
            ) : (
              <p className="font-medium text-gray-900">
                {formatPrice(item.price, item.currency)}
              </p>
            )}
            <div className="flex">
              {isCurrentUser ? (
                <DeleteVehicleButton
                  data={item}
                  onClick={toggleDeleteModal}
                  loading={loading}
                  asIcon
                />
              ) : null}
              {isCurrentUser ? <EditVehicleButton data={item} asIcon /> : null}
              <GridItemWatchlistButton item={item} />
            </div>
          </div>

          <div>
            <h3 className="text-sm text-gray-700">
              <span aria-hidden="true" className="absolute inset-0" />
              <span className="capitalize">{item.brand}</span>{" "}
              <span className="capitalize">{item.model}</span>{" "}
              <span className="capitalize">{item.year}</span>
            </h3>
          </div>
        </div>
        <div className="flex items-center gap-x-1 mt-1 mr-1">
          <div className="relative">
            <VerifiedUserIcon
              className={classNames(
                "h-5 w-5",
                item.listingTier && item.listingTier !== TIER_KEYS.FREE
                  ? TIER_COLORS[item.listingTier]
                  : "bg-white"
              )}
              idVerified={item.user?.idVerified}
              triangle
            />
          </div>
          {item.youtubeLink ? (
            <BsYoutube className="text-red-500 w-5 h-5 animate-minipulse " />
          ) : null}
          {showCrediQLogo ? (
            <Image
              src="/partners/logo_crediq.png"
              alt="Bank Logo"
              height={19.72}
              width={68}
            />
          ) : null}
        </div>
      </div>
    </Link>
  );
};

const DEFAULT_GRID_COL_CLASSNAMES = "grid-cols-2 md:grid-cols-4 lg:grid-cols-5";

export default function Grid({
  items,
  title,
  currentVehicleSeller,
  description,
  loading,
  noPadding,
  limit,
  isCurrentUser,
  colsClassNames = DEFAULT_GRID_COL_CLASSNAMES,
  transformation,
  EmptyStateComponent,
  LastElement,
}: TProps) {
  const [deleteModalState, setDeleteModalState] =
    useState<TVehicleActionModalState>({
      open: false,
      data: null,
    });

  // const  itemsToRender = limit ? items.filter((_, idx) => idx < limit) : items;
  let itemsToRender = items;

  useEffect(() => {
    if (items) {
      itemsToRender = limit ? items.filter((_, idx) => idx < limit) : items;
    }
  }, [items]);

  const toggleDeleteModal = (data?: Vehicle) => {
    setDeleteModalState({
      open: !deleteModalState.open,
      data,
    });
  };

  const { width } = useWindowDimensions();
  const [bannersPosition, setBannersPosition] = useState(
    Math.floor(limit / 2) || 30
  );

  useEffect(() => {
    function calculateAdsPosition() {
      //this seems tricky but if you read it carefully, it makes sense
      if (typeof itemsToRender !== "undefined") {
        const halfPoint = Math.floor(itemsToRender.length / 2);
        //for mobile
        if (width < 768 && halfPoint % 2 != 0) {
          //since every-other number is divisible by 2
          return setBannersPosition(halfPoint + 1);
        }

        //for sm
        if (width >= 640 && width < 768 && halfPoint % 3 !== 0) {
          if ((halfPoint + 1) % 3 !== 0) {
            return setBannersPosition(halfPoint + 2);
          }
          return setBannersPosition(halfPoint + 1);
        }
        return setBannersPosition(Math.floor(itemsToRender.length) / 2);
      }
    }
    return calculateAdsPosition();
  }, [width, itemsToRender]);

  let Content = (
    <GridLoadingSkeleton
      transformation={transformation}
      limit={limit}
      gridColsClassNames={colsClassNames}
    />
  );

  const today = new Date();
  const newListingsDate = new Date(today.setDate(today.getDate() - 4));
  const pathname = usePathname();

  if (loading) {
    Content = (
      <GridLoadingSkeleton
        limit={limit}
        transformation={transformation}
        gridColsClassNames={colsClassNames}
      />
    );
  } else if (!loading && !items?.length) {
    Content = EmptyStateComponent ? <EmptyStateComponent /> : null;
  } else if (items?.length) {
    Content = (
      <div className={classNames("mt-6 grid gap-y-2 gap-x-2", colsClassNames)}>
        {itemsToRender.map((item, index) => {
          // const expired = isExpired(item.expires);
          // return <GridLoadingSkeleton tier="collections" />;
          const isNewListing = new Date(item.createdDate) > newListingsDate;
          const isFromSameSeller = currentVehicleSeller === item.user?._id;

          //
          if (
            itemsToRender.length > 30 &&
            index == bannersPosition &&
            pathname === "/autosusados"
          ) {
            return (
              <>
                <div className="col-span-2 sm:col-span-2 md:col-span-5 my-6">
                  {/* <GridBanner /> */}
                  <ResponsiveBanner />
                </div>
                <ItemComponent
                  key={index}
                  index={index}
                  isCurrentUser={isCurrentUser}
                  isFromSameSeller={isFromSameSeller}
                  isNewListing={isNewListing}
                  item={item}
                  loading={loading}
                  toggleDeleteModal={toggleDeleteModal}
                  transformation={transformation}
                  limit={limit}
                  colsClassNames={colsClassNames}
                />
              </>
            );
          }
          return (
            <ItemComponent
              colsClassNames={colsClassNames}
              key={index}
              index={index}
              isCurrentUser={isCurrentUser}
              isFromSameSeller={isFromSameSeller}
              isNewListing={isNewListing}
              item={item}
              loading={loading}
              toggleDeleteModal={toggleDeleteModal}
              transformation={transformation}
              limit={limit}
            />
          );
        })}
        {LastElement ? <LastElement /> : null}
      </div>
    );
  }

  return (
    <div className="bg-white mb-6">
      <div
        className={classNames("max-w-7xl mx-auto", noPadding ? "px-0" : "px-2")}
      >
        {title ? (
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            {title}
          </h2>
        ) : null}

        {description ? (
          <p className="mt-1 text-sm text-gray-500">{description} </p>
        ) : null}

        {Content}
      </div>
      {isCurrentUser ? (
        <DeleteVehicleModal
          modalState={deleteModalState}
          setModalState={toggleDeleteModal}
        />
      ) : null}
    </div>
  );
}

export const GridLoadingSkeleton = ({
  transformation,
  limit,
  gridColsClassNames = DEFAULT_GRID_COL_CLASSNAMES,
}: {
  limit: number;
  transformation?: string;
  gridColsClassNames?: string;
}) => {
  //amount of images shown per grid tier

  return (
    <div className={`mt-6 grid gap-y-2 gap-x-2 ${gridColsClassNames}  w-full `}>
      {[...Array(60)].map((_, index) => {
        if (index >= limit) return;
        return (
          <div
            key={index}
            className={classNames(
              "group relative cursor-pointer pb-2"
              // item.listingTier && item.listingTier !== TIER_KEYS.FREE
              //   ? TIER_COLORS[item.listingTier]
              //   : ""
            )}
          >
            <div className="relative w-full min-h-60 bg-gray-200 animate-pulse aspect-w-4 aspect-h-3  overflow-hidden group-hover:opacity-75 rounded-md shadow-md">
              <div className="h-full absolute">
                <div className="absolute right-1 top-1 z-10"></div>
                <div
                  className={`w-full h-full object-center object-cover ${
                    transformation === "t_medium"
                      ? "(max-width: 768px) 100vw, (max-width: 1200px) 25vw"
                      : "(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
                  }`}
                ></div>
              </div>
            </div>
            <div className="relative mt-2 flex justify-between flex-col px-1">
              <div className="bg-gray-200 w-1/3 animate-pulse">
                <span className="font-medium text-gray-900/0 bg-gray-200 animate-pulse">
                  30000
                </span>
              </div>
              <div>
                <h3 className="text-sm text-gray-700/0 bg-gray-200 w-1/2 animate-pulse">
                  <span
                    aria-hidden="true"
                    className=" inset-0 bg-gray-200 animate-pulse "
                  >
                    test
                  </span>
                </h3>
              </div>
            </div>
            <div className="flex items-center gap-x-2 mr-1 px-1">
              <div className="relative h-5 w-5 bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
