import APP_CONFIG from "../app.config";
import { Vehicle } from "../graphql/generated/graphql";

export const canBeFinancedByCrediQ = (vehicle: Vehicle) => {
  return (
    APP_CONFIG.partners.crediq.enabled &&
    vehicle?.financingData?.entities?.includes("credi-q")
  );
  // return (
  //   APP_CONFIG.partners.crediq.enabled &&
  //   crediQFilters.yearMin <= vehicle.year &&
  //   crediQFilters.yearMax >= vehicle.year &&
  //   crediQFilters.brand.includes(vehicle.brand.toLowerCase()) &&
  //   crediQFilters.fuel.includes(vehicle.fuel) &&
  //   crediQFilters.category.includes(vehicle.category) &&
  //   crediQFilters.priceMax >= convertPriceToCRC(vehicle.price, vehicle.currency)
  // );
};

export const getFinancingAggregation = (
  brand: string,
  model: string,
  year: number
) => {
  return [
    {
      $search: {
        index: "default",
        compound: {
          should: [
            {
              text: {
                query: model,
                path: "model",
                fuzzy: {},
                score: {
                  boost: {
                    value: 5,
                  },
                },
              },
            },
            {
              text: {
                query: brand,
                path: "brand",
                fuzzy: {},
                score: {
                  boost: {
                    value: 2,
                  },
                },
              },
            },
          ],
        },
      },
    },
    {
      $match: {
        ["years.0"]: {
          $lte: year,
        },
        ["years.1"]: {
          $gte: year,
        },
      },
    },
    {
      $addFields: {
        score: { $meta: "searchScore" },
      },
    },
    { $sort: { score: -1 } },
    {
      $match: {
        score: { $gte: 10 },
      },
    },
    {
      $limit: 5,
    },
  ];
};
